<template>
  <div class="home">
    <div class="clearfix pb20">
      <el-page-header :icon="ArrowLeft" @back="backToDocumentList">
        <template #content>
          <span class="text-large font-600 mr-3"> 文档详情 </span>
        </template>
      </el-page-header>
    </div>
    <div class="clearfix pb20">
      <div class="fl pb5 pr5 pt5">
        <el-input type="text" v-model="documentFilter.keyword" placeholder="请输入文档关键词" clearable
                  @keyup.enter="getDocumentList"></el-input>
      </div>
      <div class="fl pb5 pr5 pt5">
        <el-button
          type="primary"
          @click="getDocumentList">
          检索
        </el-button>
      </div>
      <div class="mt5 fr">
        <el-radio-group v-model="documentFilter.lang" @change="documentLangChange">
          <el-radio-button label="zh-CN" key="zh-CN">中文</el-radio-button>
          <el-radio-button :label="config.lang" :key="config.lang"
                           v-for="config in merchantServerLanguages">{{ config.name }}
          </el-radio-button>

        </el-radio-group>
      </div>
    </div>
    <div class="home-left" v-loading="loading">
      <Empty v-if="documentList.length === 0"></Empty>
      <el-table
        :data="documentList"
        class="home-list"
        style="width: 100%"
        @cell-click="cellHandleClick"
        v-else>
        <el-table-column
          label="ID"
          prop="id"
          width="100"
        >
        </el-table-column>
        <el-table-column label="标题">
          <template #default="scope">
            <div class="document-content" v-if="scope.row.title">{{ scope.row.title }}</div>
          </template>
        </el-table-column>
        <el-table-column label="域名">
          <template #default="scope">
            <div class="document-content" v-if="scope.row.url">{{ scope.row.url }}</div>
          </template>
        </el-table-column>
        <el-table-column label="状态">
          <template #default="scope">
            <el-tag :type="getDocumentTagType(scope.row.status)" v-if="scope.row.status">
              {{ getDocumentStatus(scope.row.status) }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column label="类型">
          <template #default="scope">
            <div class="document-content" v-if="scope.row.type">{{ getDocumentType(scope.row.type) }}</div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-dialog v-model="cellPreviewDialog" title="分片预览" width="80%" destroy-on-close align-center
               class="no-padding">

      <div class="home-right" v-loading="loading">
        <Empty v-if="documentPreviewList.length === 0"></Empty>
        <el-table
          :data="documentPreviewList"
          class="home-list"
          style="width: 100%"
          height="calc(100vh - 300px)"
          v-else>
          <el-table-column
            label="序号"
            type="index"
            width="60">
          </el-table-column>
          <el-table-column
            prop="text"
            label="文本">
            <template #default="scope">
              <p class="preview-cell">{{ scope.row.text }}</p>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <template #footer>
        <div class="clearfix pt5 pb5">
        </div>
      </template>
    </el-dialog>
    <div class="none">
      <a ref="downloadRef"></a>
    </div>
  </div>
</template>

<script>
import { reactive, ref } from 'vue'
import { ElMessage } from 'element-plus'
import { authApi } from '@/api'
import { computed, onMounted } from "@vue/runtime-core";
import Empty from "@/components/Empty.vue";
import store from "@/store";
import { useRoute, useRouter } from "vue-router";
import { ArrowLeft } from '@element-plus/icons-vue'
// @ is an alias to /src

export default {
  name: 'Home',
  components: { Empty },
  setup() {
    const route = useRoute()
    const router = useRouter()

    // merchant form
    const documentList = ref([])
    const merchant = computed(() => {
      return store.getters.merchant
    })

    const merchantServerLanguages = computed(() => {
      return merchant.value.server_languages.filter(item => item.is_active)
    })

    const getDocumentList = () => {
      const documentId = route.params.id
      console.log(documentId)
      loading.value = true
      authApi.getMerchantWebDocumentDetail(merchant.value.id, documentId, documentFilter).then(res => {
        console.log(res)
        documentList.value = res.data
      }).finally(() => {
        loading.value = false
      })
    }

    const documentFilter = reactive({
      keyword: '',
      sub: true,
      lang: 'zh-CN',
    })

    const documentLangChange = (lang) => {
      documentFilter.lang = lang
      getDocumentList()
    }

    const getDocumentType = (type) => {
      let typeDict = {
        '01': 'HTML',
        '02': 'PDF',
      }
      return typeDict[type] || '未知'
    }

    const getDocumentStatus = (status) => {
      let statusDict = {
        '00': '排队中',
        '10': '抓取中',
        '20': '学习中',
        '30': '已学习',
      }
      return statusDict[status] || 'warning'
    }

    const getDocumentTagType = (status) => {
      let tagDict = {
        '00': 'warning',
        '10': 'info',
        '20': 'primary',
        '30': 'success',
      }
      return tagDict[status] || 'warning'
    }

    const loading = ref(false)

    // Web Document form

    const configInfo = computed(() => {
      return store.getters.config
    })
    const activeName = ref('cn')

    const handleClick = (tab, event) => {
      console.log(tab, event)
    }
    // dataset preview
    const documentPreviewDialog = ref(false)
    const documentPreviewList = ref([])
    const documentPreview = () => {
      loading.value = true
      const data = JSON.parse(JSON.stringify(form))
      let fullField = 'content'
      if (activeName.value !== 'cn') {
        fullField = activeName.value + '_content'
        data.content = data['multilingual_data'][fullField]
      } else {
        data.content = data[fullField]
      }

      if (data.content === null || data.content === '' || data.content === undefined) {
        ElMessage({
          message: '请输入文档',
          type: 'error',
        })
        loading.value = false
        return
      }
      authApi.uploadPreview(data).then(res => {
        documentPreviewList.value = res.data
        documentPreviewDialog.value = true
      }).finally(() => {
        loading.value = false
      })
    }

    const backToDocumentList = () => {
      router.replace({
        name: 'MerchantWebDocument',
      })
    }

    const cellPreviewDialog = ref(false)
    const cellPreviewContent = ref('')

    const cellHandleClick = (row, column, event, cell) => {
      console.log(row, column, event, cell)
      loading.value = true
      const filter = {
        lang: documentFilter.lang,
      }
      authApi.getMerchantWebDocumentPreview(merchant.value.id, row.id, filter).then(res => {
        console.log(res)
        documentPreviewList.value = res.data
        cellPreviewDialog.value = true
      }).finally(() => {
        loading.value = false
      })
    }

    onMounted(() => {
      getDocumentList()
    })
    return {
      ArrowLeft,
      loading,
      documentList,
      merchant,
      merchantServerLanguages,
      documentFilter,
      documentLangChange,
      getDocumentList,
      configInfo,

      getDocumentType,
      getDocumentStatus,
      getDocumentTagType,

      documentPreviewDialog,
      documentPreviewList,
      documentPreview,
      activeName,
      handleClick,

      backToDocumentList,

      cellPreviewDialog,
      cellPreviewContent,
      cellHandleClick,
    }
  }
}
</script>
<style scoped>
.preview-cell {
  white-space: pre-line;
}

.document-tabs .el-tabs__item {
  padding: 32px;
  color: #6b778c;
  font-size: 32px;
  font-weight: 600;
}

.document-content {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
